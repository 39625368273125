.button.button {
	min-height: 2rem;
	height: auto;
	padding: 0.5rem 1rem;
	font-family: var(--font-demi);
	font-size: var(--font-size__button);
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--colour__black--100);
	color: var(--colour__black--100);
	background-color: var(--colour__white--100);
	box-shadow: none;
	&.small {
		font-size: var(--font-size__button--small);
		padding: 0 1rem;
	}
	.round {
		border-radius: var(--border-radius--small) !important; /* Override Configio */
	}
	.badge {
		width: 1rem;
		height: 1rem;
		top: -0.5rem;
		right: -0.5rem;
		border-radius: 50%;
		background-color: var(--colour__red--100);
		color: var(--colour__white--100);
		line-height: 1;
	}
}

.header .header-actions .account-buttons .button.secondary {
	border-color: var(--colour__red--100);
	background-color: var(--colour__red--100);
	color: var(--colour__white--100);
	&:hover {
		background-color: var(--colour__red--80);
	}
}

.header .header-actions .account-buttons .button.btn-toggle-cart {
	border-color: var(--colour__black--100);
	background-color: var(--colour__white--100);
	color: var(--colour__black--100);
	font-size: var(--font-size__button--small);
	padding: 0 1rem;
	&:hover {
		background-color: var(--colour__black--100);
		color: var(--colour__white--100);
	}
}
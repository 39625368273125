:root {
	--colour__white--100: #FFFFFF;
	--colour__white--off-white: #FEFEFE;
	--colour__black--100: #000000;
	--colour__black--80: #333333;
	--colour__black--70: #4D4D4D;
	--colour__black--60: #666666;
	--colour__black--30: #B3B3B3;

	--colour__cool-grey--100: #CCCCCC;
	--colour__cool-grey--80: #D6D6D6;
	--colour__cool-grey--20: #F5F5F5;
	--colour__red--100: #E30613;
	--colour__red--80: #FB535D;

	--border-radius--small: 2px;

	--font-bold: "AvenirNextLTW02-Bold", sans-serif;
	--font-demi: "AvenirNextLTW02-Demi", sans-serif;
	--font-regular: "AvenirNextLTW02-Regular", sans-serif;

	--font-size__button: 1rem;
	--font-size__button--small: 0.75rem;
	--font-size__menu: 1rem;
	--font-size__menu--small: 0.875rem;

	--settings__shadow--1: 0 0.25rem 0.5rem 0 rgba(0,0,0,0.16);
}